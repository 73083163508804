import axios from 'axios'; 

import React, { useState,useEffect } from 'react';

import {checkout} from '../components/striperedirect';

import CircularProgress from '@material-ui/core/CircularProgress';

// import useFirebase from '../components/useFirebase';

import {navigate } from 'gatsby';



function Pay(props){

    // const firebase = useFirebase();

    console.log('props in pricing', props)

 useEffect(() => {

 		document.title = 'TurtleClip | Pay';

 			var dd = localStorage.getItem('uid')
			var email = localStorage.getItem('email')


 		checkout(dd, email)

        
    },);








return (

	<div>

		<CircularProgress className="Mpbar" />

	</div>

)
}


Pay.displayName = "Pay";
export default (React.memo(Pay));
